<template>
    <router-link :to="{ 'name': 'session-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Sessions</span>
    </router-link>
    <overlay-spinner :is-showing="isFetching" v-if="isFetching" />
    <div v-else-if="!session" class="mt-4 d-flex justify-content-center py-4  ">
        <p class="text-center text-danger fw-bold text-uppercase py-4 px-4 rounded">
            Session does not exist
        </p>
    </div>
    <template v-else>
        <div class="page-header ">
            <div class="row align-items-center justify-content-between  ">
                <div class="col-sm-12 col-md-11 d-flex align-items-center mb-3">

                    <CopyText :data="session.no" resource-name="Session No" style="min-width: 13rem">
                        <h2 class="page-title text-gray-800">
                            {{ session.no }}
                        </h2>
                    </CopyText>

                    <CopyButton :data="getShowSessionPath(session.id)" resource-name="Session URL" class="mx-1"
                        transparent />

                    <span class="badge badge-pill" :class="[getSessionStatusBadge(session.sessionStatusId)]">
                        {{ getSessionStatusLabel(session.sessionStatusId) }}
                    </span>
                    <span class="badge badge-pill bg-red" v-if="session.isUrgent">
                        Urgent
                    </span>
                </div>
                <div class="col-sm-12 col-md-1">
                    <button class="btn btn-icon btn-success float-end" data-bs-toggle="modal"
                        data-bs-target="#modal-edit-session">
                        <icon name="pencil" />
                    </button>
                    <SessionEditFormModal :session="session" @session-updated="fetchSession()" />
                </div>
            </div>
        </div>

        <template v-if="activeComponent">
            <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

            <div class="py-4">
                <KeepAlive>
                    <component :is="activeComponent.component" v-bind="activeComponent.props"
                        :key="`${activeComponent.slug}-${Date.now()}`">
                    </component>
                </KeepAlive>
            </div>
        </template>


    </template>
</template>

<script setup lang="ts">
import { getSessionStatusBadge, getShowSessionPath } from '@/composable/useSession'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import SessionStatus from '@/types/SessionStatus'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, markRaw, onMounted, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useShowSessionStore } from '@/stores/showSession'
import SubMenu from '@/components/SubMenu.vue'
import CopyButton from '@/components/CopyButton.vue'
import CopyText from '@/components/CopyText.vue'
import SessionEditFormModal from '@/pages/sessions/SessionEditFormModal.vue'

const { addToastNotification } = useNotificationsStore()
const { sessionStatuses } = storeToRefs(useResourcesStore())

const showSessionStore = useShowSessionStore()
const {
    sessionId,
    session,
    isFetching
} = storeToRefs(showSessionStore)

const { fetchSession } = showSessionStore

const route = useRoute()

provide('setActiveComponent', setActiveComponent)

const activeComponent = ref<object>({})
const subMenus = ref<Array<any>>([])

sessionId.value = Number(route.params.sessionId)

fetchSession()

const getSessionStatusLabel = (statusId: number) => {
    const sessionStatus = sessionStatuses.value.find((sessionStatus: SessionStatus) => sessionStatus.id === statusId)
    return sessionStatus?.adminLabel ?? 'N/A'
}

function setActiveComponent(menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu() {
    subMenus.value = [
        {
            title: 'Overview',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionOverview.vue')
            ),
            slug: 'session-overview',
            props: {
                session: session.value
            }
        },
        {
            title: 'Orders',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionOrders.vue')
            ),
            slug: 'session-orders',
            props: {
                session: session.value,
                mainOrder: session.value?.order,
                extraOrders: session.value?.orders
            }
        },
        {
            title: 'Dependents',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionDependents.vue')
            ),
            slug: 'session-dependents',
            props: {
                dependents: session.value?.dependents ?? []
            }
        },
        {
            title: 'Carer Applications',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionCarerApplications.vue')
            ),
            slug: 'session-carer-applications',
            props: {
                session: session.value,
                preferredCarers: session.value?.preferredCarers
            }
        },
        {
            title: 'Rates & Payouts',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionRates.vue')
            ),
            slug: 'session-rates',
            props: {
                rates: session.value?.rates ?? [],
                totalRates: session.value?.totalRates,
                payRates: session.value?.payRates,
                totalPayRates: session.value?.totalPayRates
            }
        },
        {
            title: 'Review',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionReview.vue')
            ),
            slug: 'session-review',
            props: {
                sessionId: session.value?.id
            }
        },
        {
            title: 'Check List',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionChecklist.vue')
            ),
            slug: 'session-check-list',
            props: {
                document: session.value?.document
            }
        },
        {
            title: 'Session Events',
            component: defineAsyncComponent(
                () => import('@/components/SessionEventsTimeline/SessionEventsTimeline.vue')
            ),
            slug: 'session-events',
            props: {
                sessionId: session.value?.id,
                currentEvent: session.value?.event,
            }
        },
        {
            title: 'Carer Logs',
            component: defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionCarerLogs.vue')
            ),
            slug: 'session-carer-logs',
            props: {
                carerLogs: session.value?.carerLogs,
                includeImage: true
            }
        },
        {
            title: 'Change Logs',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/sessions/show/SessionActivityLogs/SessionActivityLog.vue')
            )),
            slug: 'session-activity-log',
            props: {
                activityLogs: session.value?.activityLogs,
                order: session.value?.order
            }
        }
    ]
}

const initListeners = () => {
    window.Echo?.private('backoffice')
        .listen('.SessionUpdated', ({ model }: any) => {
            if (model.id === session.value?.id) {
                session.value = model
            }
        })
}

watch(() => session.value, () => {
    configureSubMenu()
    setActiveComponent('')
})

initListeners()

</script>