<template>
    <router-link :to="{ 'name': 'customer-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Customers</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingCustomer" v-if="!user"/>
    <template v-else>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex">
                        <h2 class="page-title">{{ user.mainProfile?.fullName ?? user.name }}</h2>
                        <div class="align-self-center ms-2">
                            <status-badge
                                :show="user.mainProfile == null || user.customerOnboarding.profileState !== CustomerOnboardingEnum.PROFILE_STATE_COMPLETE_PROFILE"
                                label="Onboarding Incomplete" color="orange"></status-badge>
                            <status-badge :show="user.isBanned" label="Banned" color="red"></status-badge>
                            <status-badge :show="user.isDeleted" label="Deleted" color="gray"></status-badge>
                        </div>
                    </div>
                    <div class="page-subtitle">
                        <div class="row">
                            <div class="col-auto">
                                <icon name="id" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{ user.mainProfile?.onlineId ?? 'N/A' }}</span>
                            </div>
                            <div class="col-auto">
                                <icon name="mail" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{ user.email }}</span> <span
                                v-if="user.emailVerifiedAt" class="text-green"><icon name="circle-check"/></span>
                            </div>
                            <div class="col-auto">
                                <icon name="phone" :stroke-width="1.2" class="pe-2"/>
                                <a v-if="isValidMobileNo(user.mainProfile?.mobileNo)"
                                   :href="`https://web.whatsapp.com/send?phone=${getMobileNo(user.mainProfile?.mobileNo)}`"
                                   target="_blank"><span class="text-reset">{{ user.mainProfile?.mobileNo }}</span></a>
                                <span v-else class="text-reset">N/A</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <div class="dropdown position-static">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                :disabled="user.isDeleted">
                            Action
                        </button>
                        <div class="dropdown-menu dropdown-menu-end cursor-pointer" style="width: 240px;">
                            <a class="dropdown-item" @click.prevent="sendResetPasswordLink">
                                <icon name="password" class="me-2"/>
                                Send Reset Password Link</a>
                            <a v-if="!user.emailVerifiedAt" class="dropdown-item"
                               @click.prevent="sendVerificationEmail">
                                <icon name="mail-forward" class="me-2"/>
                                Re-send Verification Email</a>
                            <a class="dropdown-item" @click.prevent="modalDeactivateUser?.show()">
                                <icon name="ban" class="me-2"/>
                                Deactivate customer</a>
                            <a v-if="!user.isBanned" class="dropdown-item"
                               @click.prevent="modalBanCustomer?.show()">
                                <icon name="flag" class="me-2 text-red"/>
                                Ban Customer</a>
                            <a v-if="user.isBanned" class="dropdown-item" @click.prevent="updateBanStatus(false)">
                                <icon name="flag-off" class="me-2 text-green"/>
                                Unban Customer</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mt-2 mb-3">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Dependents</h4>
                                <h1 class="fw-normal mb-0">{{ user.dependents.length }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component"
                           @deleted="fetchCustomer"
                           @updated-or-created="fetchCustomer"
                           @generate-online-id="generateOnlineId"
                           v-bind="activeComponent.props"
                           :key="`${activeComponent.slug}-${Date.now()}`">
                </component>
            </KeepAlive>
        </div>
    </template>

    <modal-deactivate-user user-type="customer" :user-id="userId"
                           :modal="modalDeactivateUser"></modal-deactivate-user>

    <kc-modal modal-id="modal-ban-customer" modal-size="md">
        <div class="modal-header">
            <h4 class="modal-title">Ban Customer</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <form id="formBanCustomer" @submit.prevent="updateBanStatus(true)">
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Specify ban reason</label>
                    <div class="col">
                        <textarea class="form-control" v-model="banReason" rows="3"></textarea>
                    </div>
                    <span class="text-end">{{ banReason.length }} / 250</span>
                </div>
            </form>
        </div>

        <div class="modal-footer pt-2 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" form="formBanCustomer">
                <span>Save</span>
            </button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import StatusBadge from '@/components/StatusBadge.vue'
import {getMobileNo, isValidMobileNo} from '@/composable/profile'
import {CustomerOnboardingEnum} from '@/enums/CustomerOnboardingEnum'
import ModalDeactivateUser from '@/pages/users/ModalDeactivateUser.vue'
import AuthService from '@/services/AuthService'
import UserService from '@/services/UserService'
import CustomerService from '@/services/users/CustomerService'
import {useNotificationsStore} from '@/stores/notifications'
import User from '@/types/User'
import Modal from 'bootstrap/js/dist/modal'
import {computed, defineAsyncComponent, markRaw, onMounted, provide, ref} from 'vue'
import {useRoute} from 'vue-router'

const {addToastNotification} = useNotificationsStore()
const route = useRoute()
const userId = Number(route.params.userId)
const user = ref<User>()
const isFetchingCustomer = ref<boolean>()
const activeComponent = ref<any>({})
const subMenus = ref<Array<any>>([])
const modalBanCustomer = ref<Modal>()
const modalDeactivateUser = ref<Modal>()
const banReason = ref<string>('')

provide('setActiveComponent', setActiveComponent)

fetchCustomer()

const isUserDeleted = computed((): boolean => user.value?.isDeleted ?? false)

onMounted(() => {
    modalBanCustomer.value = new Modal('#modal-ban-customer')
    modalDeactivateUser.value = new Modal('#modal-deactivate-user')
})

function fetchCustomer(reloadComponentSlug: string = '') {
    isFetchingCustomer.value = (reloadComponentSlug === '') // this will partially reload the async component

    CustomerService.show(userId)
        .then(({data: {data}}) => {
            user.value = data

            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        })
        .finally(() => {
            isFetchingCustomer.value = false
        })
}

function setActiveComponent(menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu() {
    subMenus.value = [
        {
            title: 'Personal',
            component: defineAsyncComponent(
                () => import('@/pages/users/customers/CustomerPersonal.vue')
            ),
            slug: 'customer-personal',
            props: {userId: userId, user: user}
        },
        {
            title: 'Dependents',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDependent.vue')
            ),
            slug: 'customer-dependents',
            props: {
                dependents: user.value?.dependents,
                userId: userId,
                isUserDeleted,
                slug: 'customer-dependents'
            }
        },
        {
            title: 'Addresses',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/address/AddressIndex.vue')
            )),
            slug: 'customer-address',
            props: {
                addresses: user.value?.addresses,
                userId: userId,
                isUserDeleted,
                componentSlug: 'customer-address'
            }
        },
        {
            title: 'Sessions',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserSession.vue')
            ),
            slug: 'customer-session',
            props: {
                customerUserId: userId
            }
        },
        {
            title: 'Reviews',
            slug: 'customer-review',
            component: defineAsyncComponent(
                () => import('@/pages/users/customers/CustomerReview/CustomerReview.vue')
            ),
            props: {
                userId,
                isUserDeleted
            }
        },
        {
            title: 'Devices',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDevice.vue')
            ),
            slug: 'customer-device',
            props: {
                devices: user.value?.devices
            }
        },
        {
            title: 'Orders',
            component: defineAsyncComponent(
                () => import('@/pages/users/customers/CustomerOrder.vue')
            ),
            slug: 'customer-order',
            props: {
                userId: userId
            }
        },
        {
            title: 'Loyalty Points',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/loyalty-points/LoyaltyPointIndex.vue')
            )),
            slug: 'customer-loyalty-point',
            props: {
                userId,
                isUserDeleted
            }
        },
        {
            title: 'Agreements',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserAgreement.vue')
            )),
            slug: 'customer-agreements',
            props: {
                userAgreements: user.value?.userAgreements
            }
        },
        {
            title: 'Notifications',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserNotification.vue')
            )),
            slug: 'customer-notifications',
            props: {
                userId
            }
        },
        {
            title: 'Change Logs',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/components/UserActivityLogs/UserActivityTimeline.vue')
            )),
            slug: 'customer-activity-logs',
            props: {
                activityLogs: user.value?.activityLogs ?? []
            }
        }
    ]
}

const updateBanStatus = (isBanned: boolean) => {
    const verb = isBanned ? 'ban' : 'unban'

    UserService.updateBan(userId, {isBanned, banReason: banReason.value})
        .then(() => {
            addToastNotification({
                message: `Customer has been ${ verb }ned`,
                type: 'success'
            })
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        })
        .finally(() => {
            banReason.value = ''
            fetchCustomer()
            modalBanCustomer.value?.hide()
        })
}

const sendResetPasswordLink = () => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to send forgot password to ${ user.value?.mainProfile?.fullName }?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            AuthService.forgotPassword({email: user.value?.email})
                .then(() => {
                    addToastNotification({
                        message: `Password reset link has been sent to customer email`,
                        type: 'success'
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message: 'Internal server error. Please contact tech team.',
                        type: 'danger'
                    })
                })
                .finally(() => {
                })
        }
    })
}

const sendVerificationEmail = () => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to send verification email to ${ user.value?.mainProfile?.fullName }?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            AuthService.resendVerifyEmail({email: user.value?.email})
                .then(() => {
                    addToastNotification({
                        message: `Verification email has been sent to customer email`,
                        type: 'success'
                    })
                })
                .catch(({response: {status}}) => {
                    let message = 'Internal server error. Please contact tech team.'
                    if (status === 429) {
                        message = 'You have re-send too many verification email. Please try again in 15 minutes.'
                    }

                    addToastNotification({
                        message,
                        type: 'danger'
                    })
                })
                .finally(() => {
                })
        }
    })
}

const generateOnlineId = () => {
    CustomerService.generateOnlineId(userId)
        .then((response) => {
            if (user.value && response.data.data.onlineId) {
                user.value.mainProfile.onlineId = response.data.data.onlineId

                addToastNotification({
                    message: response.data.message,
                    type: response.data.data.onlineId == null ? 'danger' : 'success'
                })
            }
        })
        .catch(({response: {status}}) => {
            let message = 'Internal server error. Please contact tech team.'
            if (status === 429) {
                message = 'You have generated too many online ID. Please try again in 10 minutes.'
            }

            addToastNotification({
                message,
                type: 'danger'
            })
        })
}
</script>